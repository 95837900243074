/* eslint-disable react/prop-types */
import {
  Button,
  Input,
  Label,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  Sheet,
  SheetContent,
  SheetFooter,
  SheetHeader,
  SheetTitle,
} from "@egaranti/components";

import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import useExtendedWarrantyStore from "@/stores/useExtendedWarranty";

const FilterSheet = ({ onOpenChange, open }) => {
  const { t } = useTranslation();
  const { packageFilters, setPackageFilters, getPackages } = useExtendedWarrantyStore();
  const [filtersInSheet, setFiltersInSheet] = useState({ ...packageFilters });

  useEffect(() => {
    setFiltersInSheet({ ...packageFilters });
  }, [packageFilters]);

  const onSaveFilter = () => {
    onOpenChange(false);
    setPackageFilters({
      ...packageFilters,
      ...filtersInSheet,
      page: 1,
    });
    getPackages();
  };

  return (
    <Sheet open={open} onOpenChange={onOpenChange}>
      <SheetContent className="m-4 rounded-lg bg-white p-4">
        <SheetHeader>
          <SheetTitle>{t("common.addFilter")}</SheetTitle>
        </SheetHeader>
        <div className="mt-4 flex flex-col gap-4">
          <div>
            <Label>{t("common.name")}</Label>
            <Input
              value={filtersInSheet.name}
              onChange={(e) =>
                setFiltersInSheet({
                  ...filtersInSheet,
                  name: e.target.value,
                })
              }
              placeholder={t("common.search")}
            />
          </div>
          <div>
            <Label>{t("common.status")}</Label>
            <Select
              value={filtersInSheet.status}
              onValueChange={(value) =>
                setFiltersInSheet({
                  ...filtersInSheet,
                  status: value,
                })
              }
            >
              <SelectTrigger>
                <SelectValue placeholder={t("common.status")} />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="active">{t("common.active")}</SelectItem>
                <SelectItem value="inactive">{t("common.inactive")}</SelectItem>
              </SelectContent>
            </Select>
          </div>
          <div>
            <Label>{t("common.duration")}</Label>
            <Select
              value={filtersInSheet.duration}
              onValueChange={(value) =>
                setFiltersInSheet({
                  ...filtersInSheet,
                  duration: value,
                })
              }
            >
              <SelectTrigger>
                <SelectValue placeholder={t("common.duration")} />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="1">{t("common.oneYear")}</SelectItem>
                <SelectItem value="2">{t("common.twoYears")}</SelectItem>
              </SelectContent>
            </Select>
          </div>
        </div>
        <SheetFooter className="mt-4">
          <Button onClick={() => onSaveFilter()}>{t("common.save")}</Button>
        </SheetFooter>
      </SheetContent>
    </Sheet>
  );
};

export default FilterSheet;
