import { useTranslation } from "react-i18next";

import { ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function getHeaderTitle(path: string) {
  const { t } = useTranslation();

  switch (path) {
    case "/":
      return t("titles.welcome");
    case "/inbox":
      return t("titles.welcome");
    case "/products/products":
      return t("titles.products");
    case "/products/integrations":
      return t("titles.integrations");
    case "/customers/individual":
      return t("titles.individualCustomers");
    case "/customers/corporate":
      return t("titles.corporateCustomers");
    case "/claims/service":
      return t("titles.serviceClaims");
    case "/claims/setup":
      return t("titles.setupClaims");
    case "/claims/service-record":
      return t("titles.serviceRecords");
    case "/applications":
      return t("titles.applications");
    case "/settings":
      return t("titles.settings");
    case "/settings/account":
      return t("titles.accountSettings");
    case "/settings/employees":
      return t("titles.employeeSettings");
    case "/settings/documents":
      return t("titles.documents");
    case "/settings/language":
      return t("titles.languageSettings");
    case "/additional-services":
      return t("titles.additionalServices");
    case "/settings/activations":
      return t("titles.activationSettings");
    case "/extended-warranties":
      return t("titles.extendedWarranties");
    case "/extended-warranties/new":
      return t("titles.extendedWarranties");
    default:
      return "";
  }
}

export function checkRole(user, section, action) {
  if (!user?.roles?.[section]) return false;

  const hasRole = user?.roles?.[section]?.includes(action);

  return hasRole;
}
