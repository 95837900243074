import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
  TableSkeleton,
} from "@egaranti/components";

import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import useExtendedWarrantyStore from "@/stores/useExtendedWarranty";

import FilterSheet from "@/components/extendedWarranty/packages/filterSheet";
import TableHeaderWrapper from "@/components/shared/tableHeaderWrapper";

import FilterIcon from "@/assets/icons/filter.svg?react";
import PlusIcon from "@/assets/icons/plus.svg?react";

const Packages = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isFilterOpen, setIsFilterOpen] = useState(false);

  const { packages, loading, packageFilters, getPackages, setPackageFilters } =
    useExtendedWarrantyStore();

  useEffect(() => {
    getPackages();
  }, [getPackages]);

  const handleFilter = (newFilters) => {
    setPackageFilters({ ...packageFilters, ...newFilters, page: 1 });
    getPackages();
  };

  const onPageChange = (page) => {
    setPackageFilters({ ...packageFilters, page });
    getPackages();
  };

  const onPageSizeChange = (size) => {
    setPackageFilters({ ...packageFilters, size, page: 1 });
    getPackages();
  };

  return (
    <main className="min-h-full bg-[#F9FAFC] p-4">
      <div className="flex items-center justify-end gap-4 pb-4"></div>
      <div className="rounded-lg">
        <div className="flex justify-end pb-6">
          <Button onClick={() => navigate("/extended-warranties/packages/new")}>
            <PlusIcon />
            <span>{t("extendedWarrantiesPage.newPackage")}</span>
          </Button>
        </div>
        <TableHeaderWrapper className="flex items-center justify-end gap-5">
          <Button
            className="min-w-[150px] font-medium"
            onClick={() => setIsFilterOpen(true)}
            variant="secondaryGray"
          >
            <FilterIcon />
            <span>{t("common.filter")}</span>
          </Button>
        </TableHeaderWrapper>
        <div className="rounded-lg bg-white">
          {loading ? (
            <Table>
              <TableSkeleton colSpan={6} />
            </Table>
          ) : (
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead>{t("extendedWarranties.name")}</TableHead>
                  <TableHead>{t("extendedWarranties.duration")}</TableHead>
                  <TableHead>{t("extendedWarranties.status")}</TableHead>
                  <TableHead>{t("extendedWarranties.salesChannel")}</TableHead>
                  <TableHead>{t("extendedWarranties.commission")}</TableHead>
                  <TableHead>{t("extendedWarranties.createdAt")}</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {packages.map((pkg) => (
                  <TableRow
                    key={pkg.id}
                    onClick={() =>
                      navigate(`/extended-warranties/packages/${pkg.id}`)
                    }
                    className="cursor-pointer"
                  >
                    <TableCell>{pkg.name}</TableCell>
                    <TableCell>{pkg.duration}</TableCell>
                    <TableCell>{pkg.status}</TableCell>
                    <TableCell>{pkg.salesChannel}</TableCell>
                    <TableCell>{pkg.commission}</TableCell>
                    <TableCell>{pkg.createdAt}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
        </div>
      </div>

      <FilterSheet
        open={isFilterOpen}
        onOpenChange={setIsFilterOpen}
        onFilter={handleFilter}
      />
    </main>
  );
};

export default Packages;
