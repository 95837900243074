import { DialogTrigger } from "@egaranti/components";
import {
  Button,
  Dialog,
  DialogClose,
  DialogContent,
  DialogTitle,
  FileLink,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
  Input,
  Label,
  ScrollArea,
  useToast,
} from "@egaranti/components";
import { zodResolver } from "@hookform/resolvers/zod";

import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Editor from "react-simple-wysiwyg";

import documentsService from "@/services/documentsService";

import * as z from "zod";

const CreateDefaultDocumentModal = ({ children, setRelatedProductCount }) => {
  const { t } = useTranslation();
  const { toast } = useToast();
  const [isExist, setIsExist] = useState(false);
  const [initialLogo, setInitialLogo] = useState(null);
  const [initialSignature, setInitialSignature] = useState(null);

  const form = useForm({
    resolver: zodResolver(
      z.object({
        name: z.string(),
        address: z.string(),
        phone: z.string(),
        fax: z.string(),
        warrantyConditions: z.string().optional(),
        logo: z.any().refine(
          (file) => {
            if (initialLogo) return true;
            return file instanceof File;
          },
          {
            message: t("common.validateMsg.requiredField"),
          },
        ),
        signature: z.any().refine(
          (file) => {
            if (initialLogo) return true;
            return file instanceof File;
          },
          {
            message: t("common.validateMsg.requiredField"),
          },
        ),
      }),
    ),
    defaultValues: {
      name: "",
      address: "",
      phone: "",
      fax: "",
      logo: "",
      signature: "",
      warrantyConditions: "",
    },
  });

  const getWarrantyDocuments = () => {
    documentsService.getDefaultWarrantyDocuments().then((res) => {
      form.reset(res);
      setInitialLogo(res.logo);
      setInitialSignature(res.signature);
      setRelatedProductCount(res.relatedProductCount);
      setIsExist(true);
    });
  };

  const onSubmit = (data) => {
    const multiPartData = new FormData();

    if (data.logo) multiPartData.append("logo", data.logo);
    if (data.signature) multiPartData.append("signature", data.signature);

    if (isExist) {
      if (typeof data.logo === "string") multiPartData.delete("logo");
      if (typeof data.signature === "string") multiPartData.delete("signature");

      documentsService
        .updateDefaultWarrantyDocument(form.getValues(), multiPartData)
        .then(() => {
          toast({
            title: t("documentsPage.defaultDocumentCreated"),
            variant: "success",
          });
        });
    } else {
      documentsService
        .createDefaultWarrantyDocument(data, multiPartData)
        .then(() => {
          toast({
            title: t("documentsPage.defaultDocumentCreated"),
            variant: "success",
          });
        });
    }
  };

  useEffect(() => {
    getWarrantyDocuments();
  }, []);

  return (
    <Dialog
      onOpenChange={(isOpen) => {
        getWarrantyDocuments();
      }}
    >
      <DialogTrigger>{children}</DialogTrigger>
      <DialogContent className="max-w-fit bg-[#F9FAFB] pb-0">
        <DialogTitle>Varsayılan Garanti Belgesi Oluştur</DialogTitle>
        <ScrollArea className="h-[550px] border-b">
          <div className="flex flex-col items-center gap-4 py-10">
            <svg
              width="72"
              height="72"
              viewBox="0 0 72 72"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect x="4" y="4" width="64" height="64" rx="32" fill="#CCDBFA" />
              <rect
                x="4"
                y="4"
                width="64"
                height="64"
                rx="32"
                stroke="#AAC2F7"
                stroke-width="8"
              />
              <path
                d="M46.6668 33.9998V29.0665C46.6668 26.8263 46.6668 25.7062 46.2309 24.8505C45.8474 24.0979 45.2354 23.486 44.4828 23.1025C43.6271 22.6665 42.507 22.6665 40.2668 22.6665H31.7335C29.4933 22.6665 28.3732 22.6665 27.5175 23.1025C26.7649 23.486 26.153 24.0979 25.7695 24.8505C25.3335 25.7062 25.3335 26.8263 25.3335 29.0665V42.9332C25.3335 45.1734 25.3335 46.2935 25.7695 47.1491C26.153 47.9018 26.7649 48.5137 27.5175 48.8972C28.3732 49.3332 29.4933 49.3332 31.7335 49.3332H36.0002M38.6668 34.6665H30.6668M33.3335 39.9998H30.6668M41.3335 29.3332H30.6668M44.0002 47.9998V39.9998M40.0002 43.9998H48.0002"
                stroke="#0049E6"
                stroke-width="2.5"
                strokeLinecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <h2 className="text-lg font-semibold text-[#111729]">
              Varsayılan Garanti Belgesi Oluştur
            </h2>
            <Button
              variant="secondaryGray"
              size="full"
              onClick={() => documentsService.previewDefaultWarrantyDocument()}
            >
              Önizle
            </Button>
            <p className="text-center font-medium text-[#677389]">
              Garanti belgesinde bulunması gereken alanları doldurunuz.
            </p>
            <Form {...form}>
              <form
                onSubmit={form.handleSubmit(onSubmit)}
                className="mt-5 w-full space-y-5"
              >
                <FormField
                  control={form.control}
                  name="name"
                  render={({ field }) => (
                    <FormItem>
                      <Label>Firma Adı</Label>
                      <FormControl>
                        <Input {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="address"
                  render={({ field }) => (
                    <FormItem>
                      <Label>Adres</Label>
                      <FormControl>
                        <Input {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="phone"
                  render={({ field }) => (
                    <FormItem>
                      <Label>Telefon</Label>
                      <FormControl>
                        <Input {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="fax"
                  render={({ field }) => (
                    <FormItem>
                      <Label>Faks</Label>
                      <FormControl>
                        <Input {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                {initialLogo && form.watch("logo") ? (
                  <div className="flex flex-col gap-2">
                    <Label>Logo</Label>
                    <FileLink
                      onDeleted={() => {
                        form.setValue("logo", null);
                        setInitialLogo(null);
                      }}
                      url={
                        typeof form.watch("logo") === "string"
                          ? form.watch("logo")
                          : ""
                      }
                    />
                  </div>
                ) : (
                  <FormField
                    control={form.control}
                    name="logo"
                    render={({ field }) => (
                      <FormItem>
                        <Label>Logo</Label>
                        <FormControl>
                          <Input
                            onChange={(e) =>
                              field.onChange(
                                e.target.files ? e.target.files[0] : null,
                              )
                            }
                            type="file"
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                )}
                {initialSignature && form.watch("signature") ? (
                  <div className="flex flex-col gap-2">
                    <Label>İmza/Kaşe</Label>
                    <FileLink
                      onDeleted={() => {
                        form.setValue("signature", null);
                        setInitialSignature(null);
                      }}
                      url={
                        typeof form.watch("signature") === "string"
                          ? form.watch("signature")
                          : ""
                      }
                    />
                  </div>
                ) : (
                  <FormField
                    control={form.control}
                    name="signature"
                    render={({ field }) => (
                      <FormItem>
                        <Label>İmza/Kaşe</Label>
                        <FormControl>
                          <Input
                            type="file"
                            onChange={(e) => {
                              console.log(e.target.files[0]);
                              field.onChange(
                                e.target.files ? e.target.files[0] : null,
                              );
                            }}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                )}
                <FormField
                  control={form.control}
                  name="warrantyConditions"
                  render={({ field }) => (
                    <FormItem>
                      <Label>Garanti Şartları</Label>
                      <FormControl>
                        <Editor {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </form>
            </Form>
          </div>
        </ScrollArea>
        <div className="flex w-full justify-center gap-4 pb-5">
          <DialogClose asChild>
            <Button variant="secondaryGray" size="full">
              Vazgeç
            </Button>
          </DialogClose>
          <Button size="full" onClick={form.handleSubmit(onSubmit)}>
            Kaydet
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default CreateDefaultDocumentModal;
