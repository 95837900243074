import useAppStore from "@/stores/useAppStore";
import BrandLogo from "./brandLogo";
import Navbar from "./navbar";

const Sidebar = () => {
  const { openMobileMenu, setOpenMobileMenu } = useAppStore();

  return (
    <>
      <aside
        id="default-sidebar"
        className="hidden h-screen w-full max-w-[300px] flex-col border-r bg-white px-4 py-6 md:flex"
        aria-label="Sidebar"
      >
        <BrandLogo />
        <Navbar />
      </aside>

      {/* Mobile Sidebar */}
      {openMobileMenu && (
        <div className="fixed inset-0 z-50 md:hidden">
          <div
            className="fixed inset-0 bg-gray-600 bg-opacity-50"
            onClick={() => setOpenMobileMenu(false)}
          />
          <aside className="fixed bottom-0 left-0 top-0 flex w-[300px] flex-col bg-white px-4 py-6">
            <div className="mb-4 flex items-center justify-between">
              <BrandLogo />
              <button
                onClick={() => setOpenMobileMenu(false)}
                className="rounded-lg p-2 hover:bg-gray-100"
              >
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M18 6L6 18M6 6L18 18" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
              </button>
            </div>
            <Navbar />
          </aside>
        </div>
      )}
    </>
  );
};

export default Sidebar;
