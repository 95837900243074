import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import AuthService from "@/services/authService";

const BrandLogo = () => {
  const { t } = useTranslation();
  const [logo, setLogo] = useState(null);

  useEffect(() => {
    AuthService.getLogo().then((res) => {
      if (res) setLogo(res);
    });
  }, []);

  const onClearLogo = () => {
    AuthService.deleteLogo().then(() => {
      setLogo(null);
    });
  };

  const onUploadLogo = (e) => {
    const file = e.target.files[0];
    AuthService.uploadLogo(file).then(() => {
      AuthService.getLogo().then((res) => {
        setLogo(res);
      });
    });
  };

  if (logo) {
    return (
      <div className="group relative border-b border-gray-200 pb-6">
        <div className="flex items-center justify-center">
          <img src={logo} alt="logo" className="max-h-16" />
        </div>
        <button
          onClick={onClearLogo}
          className="absolute right-0 top-0 hidden rounded-full bg-white p-1 hover:bg-red-50 group-hover:block"
        >
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="text-red-500"
          >
            <path
              d="M2 2L14 14"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M2 14L14 2"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </button>
      </div>
    );
  }

  return (
    <div className="border-b border-gray-200 pb-6">
      <div
        role="button"
        className="flex items-center justify-center rounded-lg border border-dashed border-[#CDD5E0] bg-[#F9FAFC] p-4 text-[#667085]"
      >
        <span className="text-center">
          {t("common.toAddLogo")}
          <br />
          <button
            onClick={() => document.getElementById("file").click()}
            className="focus:ring-blue-5 font-medium text-blue-600"
          >
            {t("common.clickHere")}
          </button>
          <input
            id="file"
            type="file"
            className="hidden"
            onChange={(e) => onUploadLogo(e)}
          />
        </span>
      </div>
    </div>
  );
};

export default BrandLogo;
