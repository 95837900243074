import React from "react";

import AuthHeader from "@/components/auth/authHeader";
import RegisterForm from "@/components/auth/registerForm";

const Register = () => {
  return (
    <main className="flex min-h-screen flex-col bg-gradient-to-r from-[#F9FAFC] to-[#E3E7EF]">
      <AuthHeader className="bg-transparent" />
      <RegisterForm />
    </main>
  );
};

export default Register;
