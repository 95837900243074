import { Button } from "@egaranti/components";
import { Input } from "@egaranti/components";
import { Label } from "@egaranti/components";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@egaranti/components";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "@egaranti/components";
import { Checkbox } from "@egaranti/components";
import { useToast } from "@egaranti/components";
import { zodResolver } from "@hookform/resolvers/zod";

import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import authService from "@/services/authService";

import BrandLogo from "@/assets/icons/registerbrands.svg?react";

import * as z from "zod";

const RegisterForm = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { toast } = useToast();

  const formSchema = z.object({
    merchantTitle: z
      .string({
        message: t("common.validateMsg.requiredField"),
      })
      .min(3, {
        message: t("common.validateMsg.companyNameMin"),
      })
      .max(50, {
        message: t("common.validateMsg.companyNameMax"),
      }),
    firstName: z
      .string({
        message: t("common.validateMsg.requiredField"),
      })
      .min(2, {
        message: t("common.validateMsg.firstNameMin"),
      })
      .max(50, {
        message: t("common.validateMsg.firstNameMax"),
      }),
    lastName: z
      .string({ message: t("common.validateMsg.requiredField") })
      .min(2, {
        message: t("common.validateMsg.lastNameMin"),
      })
      .max(50, {
        message: t("common.validateMsg.lastNameMax"),
      }),
    email: z
      .string({ message: t("common.validateMsg.requiredField") })
      .min(3, {
        message: t("common.validateMsg.emailMin"),
      })
      .max(50, {
        message: t("common.validateMsg.emailMax"),
      }),
    countryCode: z.string({ message: t("common.validateMsg.requiredField") }),
    phone: z
      .string({ message: t("common.validateMsg.requiredField") })
      .min(10, {
        message: t("common.validateMsg.phoneNumber"),
      })
      .max(10, {
        message: t("common.validateMsg.phoneNumber"),
      }),
    password: z
      .string({ message: t("common.validateMsg.requiredField") })
      .min(8, {
        message: t("common.validateMsg.passwordMin"),
      })
      .max(50, {
        message: t("common.validateMsg.passwordMax"),
      }),
    passwordConfirm: z
      .string({ message: t("common.validateMsg.requiredField") })
      .min(8, {
        message: t("common.validateMsg.passwordMin"),
      })
      .max(50, {
        message: t("common.validateMsg.passwordMax"),
      })
      .refine((data) => data.password === data.passwordConfirm, {
        message: t("common.validateMsg.passwordsDoNotMatch"),
        path: ["passwordConfirm"],
      }),
    countryValues: z.array(z.string()),
    regionValue: z.string(),
  });

  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: {
      merchantTitle: "",
      firstName: "",
      lastName: "",
      countryCode: "TR",
      website: "",
      phone: "",
      email: "",
      password: "",
      regionValue: "TR",
      countryValues: ["TR"],
    },
  });

  const [regionOptions, setRegionOptions] = useState([]);
  const [countryOptions, setCountryOptions] = useState([]);

  useEffect(() => {
    authService.getRegions().then((res) => {
      setRegionOptions(res);
    });
  }, []);

  const onChangeRegion = (value) => {
    authService.getCountries(value).then((res) => {
      setCountryOptions(res);
      form.setValue("countryValues", []);
    });
  };

  const onChangeCountry = (value) => {
    form.setValue(
      "countryValues",
      value.map((item) => item.value),
    );
  };

  const onSubmit = (values) => {
    authService
      .register(values)
      .then(() => {
        form.reset();
        toast({
          variant: "success",
          description: t("common.demoRequestSuccess"),
        });
        navigate("/login");
      })
      .catch((err) => {
        toast({
          variant: "error",
          description: err.response.data?.errors[0].message,
        });
      });
  };

  return (
    <div className="flex w-full flex-col justify-between gap-12 bg-gradient-to-r from-[#F9FAFC] to-[#E3E7EF] p-6 md:flex-row md:p-12">
      <div className="flex w-full max-w-3xl flex-col gap-4">
        <h1 className="text-4xl font-semibold text-[#101828]">
          {t("common.demoRequest")}
        </h1>
        <p className="text-md text-[#364153]">
          {t("registerForm.description")}
        </p>
        <ul className="mt-8 flex flex-col gap-8">
          <li className="flex gap-2 md:items-center">
            <TickIcon className="w-6 flex-shrink-0" />
            <p className="flex-1 text-[#677389]">
              {t(
                "registerForm.benefit1",
                "Kullanımı kolay gösterge paneli sayesinde satış sonrası hizmet süreçlerinizi detaylı bir şekilde analiz ederek operasyonel verimliliğinizi artırın.",
              )}
            </p>
          </li>
          <li className="flex gap-2 md:items-center">
            <TickIcon className="w-6 flex-shrink-0" />
            <p className="flex-1 text-[#677389]">
              {t(
                "registerForm.benefit2",
                "Düşük satış sonrası hizmet maliyetleri ve API tabanlı entegrasyonlarla müşteri hizmetleri masraflarınızı minimize edin.",
              )}
            </p>
          </li>
          <li className="flex gap-2 md:items-center">
            <TickIcon className="w-6 flex-shrink-0" />
            <p className="flex-1 text-[#677389]">
              {t(
                "registerForm.benefit3",
                "Uzun telefon görüşmelerini hızlı talep bildirimlerine dönüştürerek müşterilerinize hızlı ve etkili çözümler sunun, böylece memnuniyetlerini artırın.",
              )}
            </p>
          </li>
        </ul>
        <div className="mt-12 flex flex-col gap-9">
          <h3 className="text-lg font-medium text-[#364153]">
            {
              (t("registerForm.requestDemoBrands"),
              "Garanti süreçlerini yönetmek için egaranti'ye güvenen markalar")
            }
          </h3>
          <BrandLogo className="h-auto w-full md:max-w-xl" />
        </div>
      </div>
      <div className="w-full rounded-lg md:max-w-sm">
        <Form autoComplete="off" {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-5">
            <FormField
              control={form.control}
              name="merchantTitle"
              render={({ field }) => (
                <FormItem>
                  <Label>{t("registerForm.merchantTitle")}</Label>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="firstName"
              render={({ field }) => (
                <FormItem>
                  <Label>{t("registerForm.firstName")}</Label>
                  <FormControl>
                    <Input placeholder="" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="lastName"
              render={({ field }) => (
                <FormItem>
                  <Label>{t("registerForm.lastName")}</Label>
                  <FormControl>
                    <Input placeholder="" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="email"
              render={({ field }) => (
                <FormItem>
                  <Label>{t("registerForm.email")}</Label>
                  <FormControl>
                    <Input type="email" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <div className="flex gap-4">
              <FormField
                control={form.control}
                name="countryCode"
                render={({ field }) => (
                  <FormItem>
                    <Label>{t("registerForm.countryCode")}</Label>
                    <Select
                      onValueChange={field.onChange}
                      defaultValue={field.value}
                    >
                      <FormControl>
                        <SelectTrigger>
                          <SelectValue placeholder="" />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        <SelectItem value="TR">+90</SelectItem>
                        <SelectItem value="US">+1</SelectItem>
                        <SelectItem value="UZ">+998</SelectItem>
                      </SelectContent>
                    </Select>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="phone"
                render={({ field }) => (
                  <FormItem className="w-full">
                    <Label>{t("registerForm.phone")}</Label>
                    <FormControl>
                      <Input type="tel" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            <FormField
              control={form.control}
              name="password"
              render={({ field }) => (
                <FormItem>
                  <Label>{t("registerForm.password")}</Label>
                  <FormControl>
                    <Input type="password" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="passwordConfirm"
              render={({ field }) => (
                <FormItem>
                  <Label>{t("registerForm.passwordConfirm")}</Label>
                  <FormControl>
                    <Input type="password" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            {/* <div className="flex flex-col gap-6">
            <FormField
              control={form.control}
              name="regionValue"
              render={({ field }) => (
                <FormItem>
                  <Label>Bölge</Label>
                  <FormControl>
                    <RadioGroup
                      value={field.value}
                      onValueChange={(value) => {
                        onChangeRegion(value);
                        field.onChange(value);
                      }}
                    >
                      {regionOptions?.map((region, index) => (
                        <div
                          key={index}
                          className="flex items-center space-x-2"
                        >
                          <RadioGroupItem value={region.value} id={region.id} />
                          <Label htmlFor={region.value}>{region.label}</Label>
                        </div>
                      ))}
                    </RadioGroup>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            {countryOptions.length > 0 && (
              <FormField
                control={form.control}
                name="country"
                render={({ field }) => (
                  <FormItem>
                    <Label>Ülke</Label>
                    <FormControl>
                      <MultiSelect
                        placeholder="Ülke seçiniz"
                        options={countryOptions}
                        value={field.value}
                        onSelect={onChangeCountry}
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                    <FormDescription>
                      Birden fazla ülke seçebilirsiniz.
                    </FormDescription>
                  </FormItem>
                )}
              />
            )}
          </div> */}
            <FormField
              control={form.control}
              name="terms"
              render={({ field }) => (
                <FormItem className="flex cursor-pointer items-center gap-1">
                  <FormControl>
                    <Label
                      htmlFor="terms"
                      className="flex cursor-pointer items-center gap-1 text-gray-500"
                    >
                      <Checkbox
                        {...field}
                        id="terms"
                        name="terms"
                        control={form.control}
                      />
                      {t("registerForm.terms")}
                    </Label>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <div className="flex flex-col gap-4">
              <Button type="submit">{t("registerForm.requestDemo")}</Button>
            </div>
          </form>
        </Form>
      </div>
    </div>
  );
};

const TickIcon = (props) => (
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect x="2" y="2" width="24" height="24" rx="12" fill="#AAC2F7" />
    <rect
      x="2"
      y="2"
      width="24"
      height="24"
      rx="12"
      stroke="#CCDBFA"
      stroke-width="4"
    />
    <path
      d="M18 11.5L12.5 17L10 14.5"
      stroke="#0040C9"
      stroke-width="1.6"
      strokeLinecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export default RegisterForm;
