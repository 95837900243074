import { Button } from "@egaranti/components";

import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import { Breadcrumb, BreadcrumbItem } from "@/components/shared/breadcrumbs";

import { ArrowLeft, Package, Shield, User } from "lucide-react";

const PackageDetail = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();

  const InfoItem = ({ label, value }) => (
    <div className="mb-4 flex justify-between">
      <dt className="text-sm font-medium text-gray-700">{label}</dt>
      <dd className="text-sm font-semibold text-gray-700">{value}</dd>
    </div>
  );

  // fake dummy data for the package
  const packageData = {
    id: 1,
    name: "Basic",
    description: "Basic extended warranty package",
    price: 100,
    duration: 1,
  };

  // fake dummy data for customer, product and warranty info
  const data = {
    firstName: "John",
    lastName: "Doe",
    email: "john.doe@example.com",
    phone: "123-456-7890",
    productName: "Example Product",
    brand: "Example Brand",
    productPrice: 1000,
    offerPrice: 900,
    extendedWarrantyYear: 2,
    extendedWarrantyExpireDate: "2025-01-01",
    externalProductName: "External Product",
    externalOrderId: "EXT-12345",
  };

  return (
    <main className="flex min-h-screen flex-col bg-[#F9FAFC]">
      <Breadcrumb>
        <BreadcrumbItem link="/extended-warranties/packages">
          {t("extendedWarrantiesPage.title")}
        </BreadcrumbItem>
        <BreadcrumbItem active>
          {t("extendedWarrantiesPage.detail")} - {packageData.name}
        </BreadcrumbItem>
      </Breadcrumb>
      <div className="container mx-auto px-4 py-8">
        <div className="mb-6 mt-8 flex items-center justify-between">
          <h1 className="text-lg font-semibold tracking-tight">
            {t("extendedWarrantiesPage.packageDetail")}
          </h1>
          <Button
            onClick={() => {
              navigate(-1);
            }}
            variant="secondaryGray"
          >
            <ArrowLeft className="mr-2 h-5 w-5" />
            {t("common.cancel")}
          </Button>
        </div>

        <div className="grid gap-6 md:grid-cols-3">
          <div className="group flex flex-col gap-4 rounded-lg border bg-white">
            <div className="flex flex-row items-center justify-between space-y-0 rounded-lg rounded-b-none border-b bg-gray-50 p-5">
              <h3 className="text-sm font-medium leading-none tracking-tight">
                {t("extendedWarrantiesPage.customerInfo")}
              </h3>
              <User className="h-5 w-5 text-gray-800" />
            </div>
            <div className="p-6 pt-0">
              <InfoItem
                label={t("extendedWarrantiesPage.firstName")}
                value={data.firstName}
              />
              <InfoItem
                label={t("extendedWarrantiesPage.lastName")}
                value={data.lastName}
              />
              <InfoItem
                label={t("extendedWarrantiesPage.email")}
                value={data.email}
              />
              <InfoItem
                label={t("extendedWarrantiesPage.phone")}
                value={data.phone}
              />
            </div>
          </div>

          <div className="group flex flex-col gap-4 rounded-lg border bg-white">
            <div className="flex flex-row items-center justify-between space-y-0 rounded-lg rounded-b-none border-b bg-gray-50 p-5 group-hover:bg-gray-100">
              <h3 className="text-sm font-medium leading-none tracking-tight">
                {t("extendedWarrantiesPage.productInfo")}
              </h3>
              <Package className="text-muted-foreground h-5 w-5" />
            </div>
            <div className="p-6 pt-0">
              <InfoItem
                label={t("extendedWarrantiesPage.productName")}
                value={data.productName}
              />
              <InfoItem
                label={t("extendedWarrantiesPage.brand")}
                value={data.brand}
              />
              <InfoItem
                label={t("extendedWarrantiesPage.productPrice")}
                value={`${data.productPrice} TL`}
              />
              <InfoItem
                label={t("extendedWarrantiesPage.offerPrice")}
                value={`${data.offerPrice} TL`}
              />
            </div>
          </div>

          <div className="group flex flex-col gap-4 rounded-lg border bg-white">
            <div className="flex flex-row items-center justify-between space-y-0 rounded-lg rounded-b-none border-b bg-gray-50 p-5 group-hover:bg-gray-100">
              <h3 className="text-sm font-medium leading-none tracking-tight">
                {t("extendedWarrantiesPage.productInfo")}
              </h3>
              <Shield className="text-muted-foreground h-5 w-5" />
            </div>
            <div className="p-6 pt-0">
              <InfoItem
                label={t("extendedWarrantiesPage.extendedWarrantyYear")}
                value={data.extendedWarrantyYear}
              />
              <InfoItem
                label={t("extendedWarrantiesPage.extendedWarrantyExpireDate")}
                value={data.extendedWarrantyExpireDate}
              />
              <InfoItem
                label={t("extendedWarrantiesPage.externalProductName")}
                value={data.externalProductName}
              />
              <InfoItem
                label={t("extendedWarrantiesPage.externalOrderId")}
                value={data.externalOrderId}
              />
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default PackageDetail;
