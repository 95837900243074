import { Button } from "@egaranti/components";
import { useToast } from "@egaranti/components";
import { Tag } from "@egaranti/components";
import { Pagination } from "@egaranti/components";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@egaranti/components";
import { TableSkeleton } from "@egaranti/components";

import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import extendedWarrantyService from "@/services/extendedWarrantyService";

import useExtendedWarrantyStore from "@/stores/useExtendedWarranty";

import FilterSheet from "@/components/extendedWarranty/transactions/filterSheet";
import EmptyTable from "@/components/shared/EmptyTable";
import PageSizeSelect from "@/components/shared/pageSizeSelect";
import TableHeaderWrapper from "@/components/shared/tableHeaderWrapper";

import FilterIcon from "@/assets/icons/filter.svg?react";
import PlusIcon from "@/assets/icons/plus.svg?react";

const ExtendedWarranties = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { toast } = useToast();

  const [openFilterSheet, setOpenFilterSheet] = useState(false);

  const {
    extendedWarranties,
    loading,
    filters,
    getExtendedWarranties,
    setFilters,
  } = useExtendedWarrantyStore();

  useEffect(() => {
    getExtendedWarranties();
  }, [getExtendedWarranties]);

  const handleFilter = (newFilters) => {
    setFilters({ ...filters, ...newFilters, page: 1 });
    getExtendedWarranties();
  };

  const onPageChange = (page) => {
    setFilters({ ...filters, page });
    getExtendedWarranties();
  };

  const onPageSizeChange = (size) => {
    setFilters({ ...filters, size, page: 1 });
    getExtendedWarranties();
  };

  return (
    <main className="min-h-full bg-[#F9FAFC] p-4">
      <div className="flex items-center justify-end gap-4 pb-4"></div>
      <div className="rounded-lg">
        <div className="flex justify-end pb-6">
          {/* <Button
            onClick={() => navigate("/extended-warranties/transactions/new")}
          >
            <PlusIcon />
            <span>
              {t("extendedWarrantiesPage.new", "egaranti Plus Oluştur")}
            </span>
          </Button> */}
        </div>
        <div className="flex flex-col rounded-t-lg bg-white pb-4">
          <TableHeaderWrapper className="flex items-center justify-end gap-5">
            <Button
              className="min-w-[150px] font-medium"
              onClick={() => setOpenFilterSheet(true)}
              variant="secondaryGray"
            >
              <FilterIcon />
              <span>{t("common.addFilter")}</span>
            </Button>
          </TableHeaderWrapper>
          {loading ? (
            <Table>
              <TableSkeleton colSpan={4} />
            </Table>
          ) : extendedWarranties.length ? (
            <Table>
              <TableHeader>
                <TableHead>{t("productsPage.productName")}</TableHead>
                <TableHead>{t("extendedWarrantiesPage.customer")}</TableHead>
                <TableHead>{t("extendedWarrantiesPage.plan")}</TableHead>
                <TableHead>{t("extendedWarrantiesPage.createdDate")}</TableHead>
              </TableHeader>
              <TableBody>
                {extendedWarranties?.map((product) => (
                  <TableRow
                    onClick={() =>
                      navigate(
                        `/extended-warranties/transactions/${product.id}`,
                      )
                    }
                    role="button"
                    key={product.id}
                  >
                    <TableCell>{product.productName}</TableCell>
                    <TableCell>
                      {product.firstName} {product.lastName}
                    </TableCell>
                    <TableCell>
                      <Tag size="sm">{product.externalProductName}</Tag>
                    </TableCell>
                    <TableCell>{product.createdAt}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          ) : (
            <EmptyTable
              title={t("productsPage.productCouldNotFound")}
              desc={t("productsPage.productCouldNotFoundDesc")}
            />
          )}
          <div className="flex flex-col items-center">
            {filters.totalPages > 1 && (
              <Pagination
                totalPages={filters.totalPages}
                currentPage={filters.page}
                pageSize={filters.size}
                onPageChange={(page) => onPageChange(page)}
              />
            )}
            {!loading && (
              <PageSizeSelect
                onPageSizeChange={onPageSizeChange}
                value={filters.size}
              />
            )}
          </div>
        </div>
      </div>
      <FilterSheet
        open={openFilterSheet}
        onOpenChange={setOpenFilterSheet}
        onFilter={handleFilter}
      />
    </main>
  );
};

export default ExtendedWarranties;
