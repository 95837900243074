import axios from "@/lib/axios";

class ExtendedWarrantyService {
  constructor() {
    this.api = axios;
  }

  async getExtendedWarranties(
    query = {
      page: 1,
      size: 10,
    },
  ) {
    const { data } = await this.api.get("/extended-warranties", {
      params: query,
    });

    return data;
  }

  async getExtendedWarranty(id) {
    const { data } = await this.api.get(`/extended-warranties/${id}`);

    return data;
  }

  // Package related methods
  async getPackages(params) {
    // Simulating API call with dummy data until endpoint is ready
    const dummyData = {
      data: [
        {
          id: 1,
          name: "Basic Warranty Package",
          duration: "1 Year",
          status: "Active",
          createdAt: "2023-12-01",
          salesChannel: "Physical",
          commission: "20%",
        },
        {
          id: 2,
          name: "Premium Protection Plan",
          duration: "2 Years",
          status: "Active",
          createdAt: "2023-12-15",
          salesChannel: "Online",
          commission: "25%",
        },
        {
          id: 3,
          name: "Extended Care Plus",
          duration: "1 Year",
          status: "Inactive",
          createdAt: "2023-11-30",
          salesChannel: "Physical",
          commission: "15%",
        },
      ],
      total: 3,
    };

    return dummyData;
  }

  async getPackage(id) {
    // Simulating API call with dummy data until endpoint is ready
    return {
      id,
      name: "Basic Warranty Package",
      duration: "1 Year",
      status: "Active",
      createdAt: "2023-12-01",
      salesChannel: "Physical",
      commission: "20%",
    };
  }
}

export default new ExtendedWarrantyService();
