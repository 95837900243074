/* eslint-disable react/prop-types */
import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
  Button,
} from "@egaranti/components";

import { useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useLocation } from "react-router-dom";

import AdditionalServicesIcon from "../../assets/icons/additionalServices.svg?react";
import ApplicationsIcon from "../../assets/icons/applications.svg?react";
import ClaimsIcon from "../../assets/icons/claims.svg?react";
import CustomersIcon from "../../assets/icons/customers.svg?react";
import DownArrow from "../../assets/icons/downArrow.svg?react";
import HomeIcon from "../../assets/icons/home.svg?react";
import LogoutIcon from "../../assets/icons/logout.svg?react";
import DocumentIcon from "../../assets/icons/navbardocuments.svg?react";
import ProductIcon from "../../assets/icons/products.svg?react";
import SettingsIcon from "../../assets/icons/settings.svg?react";
import CheckRole from "../shared/checkRole";

import useAppStore from "@/stores/useAppStore";
import useAuthStore from "@/stores/useAuthStore";

import { cn } from "@/lib/utils";

const Navbar = () => {
  const { t, i18n } = useTranslation();
  const { logout } = useAuthStore();
  const { setOpenMobileMenu } = useAppStore();
  const [openSubNavKey, setOpenSubNavKey] = useState(null);

  const userCountry = localStorage.getItem("userCountry");

  const handleNavItemClick = () => {
    setOpenMobileMenu(false);
  };

  const menuItems = [
    {
      name: t("navbar.menuItems.overview"),
      key: "OVERVIEW",
      icon: <HomeIcon />,
      link: "/",
    },
    {
      name: t("navbar.menuItems.products"),
      key: "PRODUCTS",
      icon: <ProductIcon />,
      openSubNav: true,
      subMenuItems: [
        {
          name: t("navbar.menuItems.products"),
          link: "/products/products",
        },
        {
          name: t("navbar.menuItems.integrations"),
          link: "/products/integrations",
        },
      ],
    },
    {
      name: t("navbar.menuItems.clients"),
      key: "CLIENTS",
      icon: <CustomersIcon />,
      subMenuItems: [
        {
          name: t("navbar.menuItems.individual"),
          link: "/customers/individual",
        },
        {
          name: t("navbar.menuItems.corporate"),
          link: "/customers/corporate",
        },
      ],
    },
    ...(userCountry !== "UZ"
      ? [
          {
            name: t("navbar.menuItems.extraWarranty"),
            key: "ACTIVATIONS",
            icon: <AdditionalServicesIcon />,
            openSubNav: true,
            subMenuItems: [
              {
                name: t("navbar.menuItems.sales"),
                link: "/extended-warranties/transactions",
              },
              // {
              //   name: t("navbar.menuItems.packages"),
              //   link: "/extended-warranties/packages",
              // },
            ],
          },
        ]
      : []),
    {
      name: t("navbar.menuItems.claims"),
      key: "CLAIMS",
      icon: <ClaimsIcon />,
      subMenuItems: [
        {
          name: t("navbar.menuItems.service"),
          link: "/claims/service",
        },
        {
          name: t("navbar.menuItems.setup"),
          link: "/claims/setup",
        },
        {
          name: t("navbar.menuItems.serviceRecord"),
          link: "/claims/service-record",
        },
      ],
    },
    {
      name: t("navbar.menuItems.applications"),
      key: "APPS",
      icon: <ApplicationsIcon />,
      link: "/applications",
    },
    {
      name: t("navbar.menuItems.settings"),
      key: "SETTINGS",
      icon: <SettingsIcon />,
      link: "/settings",
    },
  ];

  return (
    <nav className="flex flex-1 flex-col gap-2 overflow-auto pt-6">
      {menuItems.map((item) => (
        <CheckRole key={item.name} action="VIEW" section={item.key}>
          <NavItem
            link={item.link}
            key={item.name}
            hasSubNav={item.subMenuItems}
            isOpen={openSubNavKey === item.key}
            onToggle={() =>
              setOpenSubNavKey(openSubNavKey === item.key ? null : item.key)
            }
            onClick={handleNavItemClick}
          >
            {item.icon}
            {item.name}
          </NavItem>
        </CheckRole>
      ))}
      <AlertDialog>
        <AlertDialogTrigger>
          <div
            role="button"
            tabIndex="0"
            className="flex flex-col gap-2 rounded-lg"
          >
            <div className="flex items-center rounded-lg px-5 py-4 font-medium text-[#111729] hover:bg-gray-100">
              <div className="flex items-center gap-3">
                <LogoutIcon />
                <span>{t("navbar.menuItems.logout")}</span>
              </div>
            </div>
          </div>
        </AlertDialogTrigger>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>{t("navbar.menuItems.warning")}</AlertDialogTitle>
            <AlertDialogDescription>
              {t("navbar.menuItems.logoutWarning")}
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel className="sm:mt-0">
              {t("navbar.menuItems.cancel")}
            </AlertDialogCancel>
            <Button onClick={logout} className="bg-red-600 hover:bg-red-700">
              {t("navbar.menuItems.logout")}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </nav>
  );
};

const NavItem = ({ children, hasSubNav, link, isOpen, onToggle, onClick }) => {
  const location = useLocation();

  const isActive = (url) => {
    if (!url) return false;
    return location.pathname === url;
  };

  if (hasSubNav) {
    return (
      <div className="flex flex-col gap-2 rounded-lg">
        <div
          role="button"
          tabIndex="0"
          className="flex items-center justify-between rounded-lg px-5 py-4 font-medium text-[#111729] hover:bg-gray-100"
          onClick={onToggle}
        >
          <div className="flex items-center gap-3">{children}</div>
          <svg
            className={cn("h-5 w-5 transform transition-transform", {
              "rotate-180": isOpen,
            })}
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
              clipRule="evenodd"
            />
          </svg>
        </div>
        {isOpen && hasSubNav && (
          <div className="ml-4 flex flex-col gap-2 border-l pl-4">
            {hasSubNav.map((subItem) => (
              <NavLink
                key={subItem.name}
                to={subItem.link}
                onClick={onClick}
                className={({ isActive }) =>
                  cn(
                    "rounded-lg px-5 py-4 font-medium text-[#111729] hover:bg-gray-100",
                    {
                      "bg-[#CCDBFA] text-[#002573]": isActive,
                    },
                  )
                }
              >
                {subItem.name}
              </NavLink>
            ))}
          </div>
        )}
      </div>
    );
  }

  return (
    <NavLink
      to={link}
      onClick={onClick}
      className={({ isActive: linkIsActive }) =>
        cn(
          "flex items-center gap-3 rounded-lg px-5 py-4 font-medium text-[#111729] hover:bg-gray-100",
          {
            "bg-[#CCDBFA] text-[#002573]": isActive(link) || linkIsActive,
          },
        )
      }
    >
      {children}
    </NavLink>
  );
};

export default Navbar;
