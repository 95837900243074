/* eslint-disable react/prop-types */
import { Button } from "@egaranti/components";
import { Input } from "@egaranti/components";
import { Label } from "@egaranti/components";
import { useToast } from "@egaranti/components";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@egaranti/components";
import { Pagination } from "@egaranti/components";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@egaranti/components";
import { Tag } from "@egaranti/components";

import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import individualCustomersService from "@/services/individualCustomersService";

import { Breadcrumb, BreadcrumbItem } from "@/components/shared/breadcrumbs";
import EmptyTable from "@/components/shared/EmptyTable";

import TrashIcon from "@/assets/icons/trash.svg?react";

import { ExternalLink } from "lucide-react";

// product.source enums
// EXTERNAL_INTEGRATION,
// PANEL,
// SERVICE_RECORD,
// API_INTEGRATION,
// PARTNER,
// PRODUCT_REGISTER

const CustomerEditPage = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { toast } = useToast();
  const [data, setData] = useState({});
  const [products, setProducts] = useState([]);
  const [page, setPage] = useState(1);

  useEffect(() => {
    individualCustomersService.getIndividualCustomer(id).then((res) => {
      setData(res);
    });

    individualCustomersService
      .getIndividualCustomerProducts(id, page)
      .then((res) => {
        setProducts(res.content);
        setPage(res.page);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClickDelete = (productId) => {
    individualCustomersService
      .deleteIndividualCustomerProduct(id, productId)
      .then(() => {
        toast({
          title: "Ürün silindi",
          variant: "success",
        });
      })
      .then(() => {
        individualCustomersService
          .getIndividualCustomerProducts(id, page)
          .then((res) => {
            setProducts(res.content);
            setPage(res.page);
          });
      });
  };

  const onPageChange = (page) => {
    setFilters({ ...filters, page });
    getIndividuaCustomers();
  };

  const getSourceInfo = (sourceType) => {
    switch (sourceType) {
      case "EXTERNAL_INTEGRATION":
        return {
          text: t("source.externalIntegration", "Harici Entegrasyon"),
          color: "yellow",
        };
      case "PANEL":
        return { text: t("source.panel", "Panel"), color: "blue" };
      case "SERVICE_RECORD":
        return {
          text: t("source.serviceRecord", "Servis Kayıt"),
          color: "blue",
        };
      case "API_INTEGRATION":
        return {
          text: t("source.apiIntegration", "API Entegrasyon"),
          color: "yellow",
        };
      case "PARTNER":
        return { text: t("source.partner", "Partner"), color: "yellow" };
      case "PRODUCT_REGISTER":
        return {
          text: t("source.productRegister", "Ürün Kayıt"),
          color: "green",
        };
      default:
        return { text: t("source.panel", "Panel"), color: "blue" };
    }
  };

  return (
    <main className="flex min-h-screen flex-col bg-[#F9FAFC]">
      <Breadcrumb>
        <BreadcrumbItem link="/customers/individual">
          {t("clientsPage.customerIndividual")}
        </BreadcrumbItem>
        <BreadcrumbItem active>{t("clientsPage.editCustomer")}</BreadcrumbItem>
      </Breadcrumb>
      <div className="container mx-auto my-20 flex flex-col items-center justify-center gap-6">
        <div className="formBox grid grid-cols-2 gap-4">
          <h2 className="col-span-2 mb-5 text-lg font-medium">
            {t("clientsPage.customerInfo")}
          </h2>
          <div className="flex flex-col gap-4">
            <Label className="col-span-2">{t("common.firstName")}</Label>
            <Input value={data?.firstName} disabled />
          </div>
          <div className="flex flex-col gap-4">
            <Label className="col-span-2">{t("common.lastName")}</Label>
            <Input value={data?.lastName} disabled />
          </div>
          <div className="flex flex-col gap-4">
            <Label className="col-span-2">{t("common.email")}</Label>
            <Input value={data?.email} disabled />
          </div>
          <div className="flex flex-col gap-4">
            <Label className="col-span-2">{t("common.phone")}</Label>
            <Input value={data?.phone} disabled />
          </div>
        </div>
        <div className="formBox gap-4">
          <h2 className="mb-5 text-lg font-medium">
            {t("clientsPage.products")}
          </h2>
          {products.length ? (
            <Table>
              <TableHeader className="bg-gray-50">
                <TableHead>{t("clientsPage.productName")}</TableHead>
                <TableHead>{t("clientsPage.brand")}</TableHead>
                <TableHead>{t("clientsPage.source", "Kaynak")}</TableHead>
                <TableHead>{t("clientsPage.date")}</TableHead>
                <TableHead>{t("clientsPage.delete")}</TableHead>
                <TableCell></TableCell>
              </TableHeader>
              <TableBody>
                {products?.map((product) =>
                  product.warrantySource === "PRODUCT_REGISTER" ? (
                    <ExpandableTableRow
                      key={product.id}
                      product={product}
                      onDelete={onClickDelete}
                      getSourceInfo={getSourceInfo}
                    />
                  ) : (
                    <TableRow key={product.id}>
                      <TableCell>{product.name}</TableCell>
                      <TableCell>{product.brand}</TableCell>
                      <TableCell>
                        <Tag
                          size="sm"
                          variant={getSourceInfo(product.source).color}
                        >
                          {getSourceInfo(product.warrantySource).text}
                        </Tag>
                      </TableCell>
                      <TableCell>{product.createdAt}</TableCell>
                      <TableCell>
                        <DeleteProductDialog
                          on={() => onClickDelete(product.id)}
                        />
                      </TableCell>
                    </TableRow>
                  ),
                )}
              </TableBody>
            </Table>
          ) : (
            <EmptyTable
              title={t("clientsPage.clientCouldNotFound")}
              desc={t("clientsPage.clientCouldNotFoundDesc")}
            />
          )}
          {page > 1 && (
            <Pagination
              totalPages={products?.totalPages}
              currentPage={products?.page}
              pageSize={products?.size}
              onPageChange={(page) => onPageChange(page)}
            />
          )}
        </div>
      </div>
    </main>
  );
};

export default CustomerEditPage;

const DeleteProductDialog = ({ on }) => {
  const { t } = useTranslation();

  return (
    <AlertDialog>
      <AlertDialogTrigger>
        <button className="border-none text-red-500 shadow-none">
          <TrashIcon className="bg-white text-red-500" />
        </button>
      </AlertDialogTrigger>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>{t("clientsPage.deleteWarning")}</AlertDialogTitle>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel>{t("common.cancel")}</AlertDialogCancel>
          <AlertDialogAction onClick={on}>
            {t("common.confirm")}
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

const ExpandableTableRow = ({ product, onDelete, getSourceInfo }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <>
      <TableRow
        key={product.id}
        className="cursor-pointer hover:bg-gray-50"
        onClick={() => setIsExpanded(!isExpanded)}
      >
        <TableCell>{product.name}</TableCell>
        <TableCell>{product.brand}</TableCell>
        <TableCell>
          <Tag size="sm" variant={getSourceInfo(product.warrantySource).color}>
            {getSourceInfo(product.warrantySource).text}
          </Tag>
        </TableCell>
        <TableCell>{product.createdAt}</TableCell>
        <TableCell onClick={(e) => e.stopPropagation()}>
          <DeleteProductDialog on={() => onDelete(product.id)} />
        </TableCell>
        <TableCell>{isExpanded ? "▲" : "▼"}</TableCell>
      </TableRow>
      {isExpanded && (
        <TableRow>
          <TableCell colSpan={6} className="w-full hover:bg-white">
            <div className="grid grid-cols-[300px_1fr] gap-4 p-4">
              <div className="space-y-2">
                {product.warrantyFileUrl && (
                  <div className="flex flex-col gap-4">
                    <span className="text-sm font-medium">Garanti Belgesi</span>
                    <div className="rounded-lg border bg-white p-3 shadow-sm transition-colors hover:bg-gray-50">
                      <a
                        href={product.warrantyFileUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="flex items-center justify-between text-sm"
                      >
                        <div className="flex items-center gap-2">
                          <DocumentIcon />
                          <span>Garanti Belgesi</span>
                        </div>
                        <ExternalLink className="h-4 w-4" />
                      </a>
                    </div>
                  </div>
                )}
                {product.billingFileUrl && (
                  <div className="flex flex-col gap-4">
                    <span className="text-sm font-medium">Fatura</span>
                    <div className="rounded-lg border bg-white p-4 shadow-sm transition-colors hover:bg-gray-50">
                      <a
                        href={product.billingFileUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="flex items-center justify-between text-sm"
                      >
                        <div className="flex items-center gap-2">
                          <DocumentIcon />
                          <span>Fatura</span>
                        </div>
                        <ExternalLink className="h-4 w-4" />
                      </a>
                    </div>
                  </div>
                )}
                {product.serviceFileUrl && (
                  <div className="flex flex-col gap-4">
                    <span className="text-sm font-medium">Servis Fişi</span>
                    <div className="rounded-lg border bg-white p-4 shadow-sm transition-colors hover:bg-gray-50">
                      <a
                        href={product.serviceFileUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="flex items-center justify-between text-sm"
                      >
                        <div className="flex items-center gap-2">
                          <DocumentIcon />
                          <span>Servis Fişi</span>
                        </div>
                        <ExternalLink className="h-4 w-4" />
                      </a>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </TableCell>
        </TableRow>
      )}
    </>
  );
};

const DocumentIcon = () => (
  <svg
    width="40"
    height="41"
    viewBox="0 0 40 41"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect y="0.5" width="40" height="40" rx="20" fill="#F2F5F9" />
    <path
      d="M21.6668 12.3912V15.8333C21.6668 16.3 21.6668 16.5334 21.7577 16.7117C21.8376 16.8685 21.965 16.9959 22.1218 17.0758C22.3001 17.1667 22.5335 17.1667 23.0002 17.1667H26.4423M26.6668 18.8235V24.8333C26.6668 26.2335 26.6668 26.9335 26.3943 27.4683C26.1547 27.9387 25.7722 28.3212 25.3018 28.5609C24.767 28.8333 24.067 28.8333 22.6668 28.8333H17.3335C15.9334 28.8333 15.2333 28.8333 14.6985 28.5609C14.2281 28.3212 13.8457 27.9387 13.606 27.4683C13.3335 26.9335 13.3335 26.2335 13.3335 24.8333V16.1667C13.3335 14.7665 13.3335 14.0665 13.606 13.5317C13.8457 13.0613 14.2281 12.6788 14.6985 12.4392C15.2333 12.1667 15.9334 12.1667 17.3335 12.1667H20.01C20.6215 12.1667 20.9272 12.1667 21.2149 12.2357C21.47 12.297 21.7139 12.398 21.9375 12.5351C22.1898 12.6897 22.406 12.9059 22.8384 13.3382L25.4953 15.9951C25.9276 16.4275 26.1438 16.6437 26.2984 16.896C26.4355 17.1196 26.5365 17.3635 26.5978 17.6186C26.6668 17.9063 26.6668 18.212 26.6668 18.8235Z"
      stroke="#677389"
      stroke-width="1.66667"
      strokeLinecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
