import React from "react";

import AuthHeader from "@/components/auth/authHeader";
import LoginForm from "@/components/auth/loginForm";

const Login = () => {
  return (
    <main className="flex min-h-screen flex-col items-center bg-gradient-to-r from-[#F9FAFC] to-[#E3E7EF]">
      <AuthHeader className="bg-transparent" />
      <LoginForm />
    </main>
  );
};

export default Login;
