import LangSwitch from "../layout/langSwitch";

import EgarantiLogo from "@/assets/egaranti-logo.png";

import { cn } from "@/lib/utils";

const AuthHeader = ({ props }) => {
  return (
    <header
      className={cn(
        `flex w-full items-center justify-between bg-transparent p-8`,
        props?.className,
      )}
      style={{ minHeight: "6rem" }}
    >
      <img src={EgarantiLogo} alt="Egaranti Logo" className="h-8 md:h-10" />
      <LangSwitch />
    </header>
  );
};

export default AuthHeader;
