import {
  Button,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
  Input,
  Label,
  RadioGroup,
  RadioGroupItem,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  Switch,
  Textarea,
} from "@egaranti/components";
import { zodResolver } from "@hookform/resolvers/zod";

import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { Breadcrumb, BreadcrumbItem } from "@/components/shared/breadcrumbs";
import { InputWithIcon } from "@/components/ui/inputWithIcon";

import { Info } from "lucide-react";
import * as z from "zod";

const NewPackagePage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const formSchema = z.object({
    planName: z.string().min(2, {
      message: t("extendedWarranties.validateMsg.required"),
    }),
    planType: z.string(),
    planDuration: z.string(),
    salesChannel: z.string(),
    status: z.boolean(),
    productSalePrice: z.number().min(0),
    contractorCommission: z.number().min(0).max(100),
    brandCommissionRate: z.number().min(0).max(100),
    dealerCommission: z.boolean(),
    dealerCommissionRate: z.number().min(0).max(100),
    planDescription: z.string(),
    collectionType: z.string(),
    refundPolicy: z.string(),
    generateActivation: z.boolean(),
    activationCount: z.number().int().min(0),
  });

  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: {
      planName: "",
      planType: t("extendedWarranties.extendedWarrantyPlan"),
      planDuration: "1",
      salesChannel: t("extendedWarranties.physicalSales"),
      status: true,
      productSalePrice: 20,
      contractorCommission: 60,
      brandCommissionRate: 10,
      dealerCommission: true,
      dealerCommissionRate: 0,
      planDescription: t("extendedWarranties.extendedWarrantyDescription"),
      collectionType: t("extendedWarranties.storeDealerCollection"),
      refundPolicy: t("extendedWarranties.fourteenDays"),
      generateActivation: true,
      activationCount: 100,
    },
  });

  const onSubmit = (data) => {
    console.log(data);
    // TODO: Add API call here
  };

  // log form errors
  console.log(form.formState.errors);

  return (
    <main className="flex min-h-screen flex-col bg-[#F9FAFC]">
      <Breadcrumb>
        <BreadcrumbItem link="/extended-warranties/packages">
          {t("extendedWarrantiesPage.packages")}
        </BreadcrumbItem>
        <BreadcrumbItem active>
          {t("extendedWarrantiesPage.newPackage")}
        </BreadcrumbItem>
      </Breadcrumb>
      <div className="container mx-auto px-4 py-8">
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
            {/* Basic Plan Information */}
            <div className="rounded-lg bg-white p-6 shadow-sm">
              <h2 className="mb-6 text-lg font-semibold">
                {t("extendedWarrantiesPage.packageBasicInfo")}
              </h2>

              <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
                <FormField
                  control={form.control}
                  name="planName"
                  render={({ field }) => (
                    <FormItem>
                      <Label>{t("extendedWarranties.planName")}</Label>
                      <FormControl>
                        <Input {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="planType"
                  render={({ field }) => (
                    <FormItem>
                      <Label>{t("extendedWarranties.planType")}</Label>
                      <FormControl>
                        <Input
                          {...field}
                          defaultValue={t(
                            "extendedWarranties.extendedWarrantyPlan",
                          )}
                          readOnly
                          className="bg-gray-50"
                        />
                      </FormControl>
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="planDuration"
                  render={({ field }) => (
                    <FormItem>
                      <Label>{t("extendedWarranties.planDuration")}</Label>
                      <Select
                        onValueChange={field.onChange}
                        defaultValue={field.value}
                      >
                        <SelectTrigger>
                          <SelectValue
                            placeholder={t("extendedWarranties.selectDuration")}
                          />
                        </SelectTrigger>
                        <SelectContent>
                          <SelectItem value="1">
                            {t("extendedWarranties.oneYear")}
                          </SelectItem>
                        </SelectContent>
                      </Select>
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="salesChannel"
                  render={({ field }) => (
                    <FormItem>
                      <Label>{t("extendedWarranties.salesChannel")}</Label>
                      <FormControl>
                        <Input
                          {...field}
                          defaultValue={t("extendedWarranties.physicalSales")}
                          readOnly
                          className="bg-gray-50"
                        />
                      </FormControl>
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="status"
                  render={({ field }) => (
                    <FormItem className="col-span-2">
                      <Label>{t("extendedWarranties.status")}</Label>
                      <RadioGroup
                        onValueChange={(value) =>
                          field.onChange(value === "true")
                        }
                        defaultValue={field.value ? "true" : "false"}
                        className="flex items-center gap-2"
                      >
                        <FormItem className="flex items-center space-x-3">
                          <FormControl>
                            <RadioGroupItem value="true" id="active-status" />
                          </FormControl>
                          <Label
                            htmlFor="active-status"
                            className="!mt-0 cursor-pointer"
                          >
                            {t("extendedWarranties.active")}
                          </Label>
                        </FormItem>
                        <FormItem className="flex items-center space-x-3">
                          <FormControl>
                            <RadioGroupItem value="false" id="passive-status" />
                          </FormControl>
                          <Label
                            htmlFor="passive-status"
                            className="!mt-0 cursor-pointer"
                          >
                            {t("extendedWarranties.passive")}
                          </Label>
                        </FormItem>
                      </RadioGroup>
                    </FormItem>
                  )}
                />
              </div>
            </div>

            {/* Plan Details */}
            <div className="rounded-lg bg-white p-6 shadow-sm">
              <h2 className="mb-6 text-lg font-semibold">
                {t("extendedWarrantiesPage.planDetails")}
              </h2>

              <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
                <FormField
                  control={form.control}
                  name="productSalePrice"
                  render={({ field }) => (
                    <FormItem>
                      <Label>{t("extendedWarranties.productSalePrice")}</Label>
                      <FormControl>
                        <InputWithIcon
                          type="number"
                          icon={<span className="text-[#111729]">%</span>}
                          {...field}
                          onChange={(e) =>
                            field.onChange(Number(e.target.value))
                          }
                        />
                      </FormControl>
                      <p className="mt-1 text-sm text-[#677389]">
                        {t("extendedWarranties.autoGenerateFromDevicePrice")}
                      </p>
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="contractorCommission"
                  render={({ field }) => (
                    <FormItem>
                      <Label>
                        {t("extendedWarranties.contractorCommission")}
                      </Label>
                      <FormControl>
                        <InputWithIcon
                          {...field}
                          icon={<span className="text-[#111729]">%</span>}
                          readOnly
                          className="bg-gray-50"
                          onChange={(e) =>
                            field.onChange(Number(e.target.value))
                          }
                        />
                      </FormControl>
                      <p className="mt-1 text-sm text-[#677389]">
                        {t(
                          "extendedWarranties.warrantyAndContractorCommissionRate",
                        )}
                      </p>
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="brandCommissionRate"
                  render={({ field }) => (
                    <FormItem>
                      <Label>
                        {t("extendedWarranties.brandCommissionRate")}
                      </Label>
                      <FormControl>
                        <InputWithIcon
                          type="number"
                          icon={<span className="text-[#111729]">%</span>}
                          {...field}
                          onChange={(e) =>
                            field.onChange(Number(e.target.value))
                          }
                        />
                      </FormControl>
                    </FormItem>
                  )}
                />

                <div className="flex flex-col gap-3">
                  <FormField
                    control={form.control}
                    name="dealerCommission"
                    render={({ field }) => (
                      <FormItem>
                        <Label className="flex items-center justify-between">
                          {t("extendedWarranties.dealerCommission")}
                          <FormControl>
                            <Switch
                              checked={field.value}
                              onCheckedChange={field.onChange}
                            />
                          </FormControl>
                        </Label>
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="dealerCommissionRate"
                    render={({ field }) => (
                      <FormItem>
                        <Label>
                          {t("extendedWarranties.dealerCommissionRate")}
                        </Label>
                        <FormControl>
                          <InputWithIcon
                            type="number"
                            icon={<span className="text-[#111729]">%</span>}
                            {...field}
                            disabled={!form.watch("dealerCommission")}
                            onChange={(e) =>
                              field.onChange(Number(e.target.value))
                            }
                          />
                        </FormControl>
                      </FormItem>
                    )}
                  />
                </div>

                <FormField
                  control={form.control}
                  name="planDescription"
                  render={({ field }) => (
                    <FormItem className="col-span-2">
                      <Label className="flex items-center gap-2">
                        {t("extendedWarranties.planDescription")}
                      </Label>
                      <FormControl>
                        <Textarea className="min-h-[100px]" {...field} />
                      </FormControl>
                    </FormItem>
                  )}
                />
              </div>
            </div>

            {/* Collection and Refund Details */}
            <div className="rounded-lg bg-white p-6 shadow-sm">
              <h2 className="mb-6 text-lg font-semibold">
                {t("extendedWarrantiesPage.collectionAndRefundDetails")}
              </h2>

              <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
                <FormField
                  control={form.control}
                  name="collectionType"
                  render={({ field }) => (
                    <FormItem>
                      <Label>{t("extendedWarranties.collectionType")}</Label>
                      <FormControl>
                        <Input
                          {...field}
                          defaultValue={t(
                            "extendedWarranties.storeDealerCollection",
                          )}
                          readOnly
                          className="bg-gray-50"
                        />
                      </FormControl>
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="refundPolicy"
                  render={({ field }) => (
                    <FormItem>
                      <Label>{t("extendedWarranties.refundPolicy")}</Label>
                      <FormControl>
                        <Input {...field} />
                      </FormControl>
                    </FormItem>
                  )}
                />
              </div>

              <div className="mt-6">
                <div className="flex items-start gap-6">
                  <div className="flex-1">
                    <p className="mb-4">
                      {t("extendedWarranties.generateActivationNumber")}
                    </p>
                    <FormField
                      control={form.control}
                      name="generateActivation"
                      render={({ field }) => (
                        <FormItem>
                          <FormControl>
                            <RadioGroup
                              onValueChange={(value) =>
                                field.onChange(value === "true")
                              }
                              value={field.value.toString()}
                              className="flex gap-4"
                            >
                              <div className="flex items-center space-x-2">
                                <RadioGroupItem value="true" id="yes" />
                                <Label htmlFor="yes">
                                  {t("extendedWarranties.yes")}
                                </Label>
                              </div>
                              <div className="flex items-center space-x-2">
                                <RadioGroupItem value="false" id="no" />
                                <Label htmlFor="no">
                                  {t("extendedWarranties.no")}
                                </Label>
                              </div>
                            </RadioGroup>
                          </FormControl>
                        </FormItem>
                      )}
                    />
                  </div>

                  <FormField
                    control={form.control}
                    name="activationCount"
                    render={({ field }) => (
                      <FormItem>
                        <Label>{t("extendedWarranties.activationCount")}</Label>
                        <FormControl>
                          <Input
                            type="number"
                            {...field}
                            className="w-[200px]"
                            onChange={(e) =>
                              field.onChange(Number(e.target.value))
                            }
                          />
                        </FormControl>
                      </FormItem>
                    )}
                  />
                </div>
              </div>
            </div>
            <div className="flex justify-end gap-4">
              <Button size="lg" type="submit">
                {t("common.save")}
              </Button>
            </div>
          </form>
        </Form>
      </div>
    </main>
  );
};

export default NewPackagePage;
