import {
  Button,
  FileArea,
  FileLink,
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormMessage,
  Input,
  Label,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  Switch,
  Tag,
  Textarea,
  useToast,
} from "@egaranti/components";
import { zodResolver } from "@hookform/resolvers/zod";

import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import ProductService from "@/services/productService";

import DocumentLinkCard from "@/components/products/edit/documentLinkCard";
import { Breadcrumb, BreadcrumbItem } from "@/components/shared/breadcrumbs";

import { t } from "i18next";
import { z } from "zod";

const ProductEditPage = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const { toast } = useToast();
  const [loading, setLoading] = useState(true);
  const [documents, setDocuments] = useState([]);

  const formSchema = z.object({
    name: z.string().min(1, { message: t("common.validateMsg.requiredField") }),
    model: z
      .string(t("common.validateMsg.requiredField"))
      .min(1, { message: t("common.validateMsg.requiredField") }),
    warrantyDuration: z
      .number({ required_error: t("common.validateMsg.requiredField") })
      .min(1, { message: t("common.validateMsg.requiredField") }),
    price: z
      .number({ required_error: t("common.validateMsg.requiredField") })
      .min(0, { message: t("common.validateMsg.requiredField") }),
    priceUnit: z
      .string()
      .min(1, { message: t("common.validateMsg.requiredField") }),
    additionalService: z.boolean(),
  });

  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: async () => ProductService.getProduct(id),
  });

  const onSubmit = (data) => {
    const dataToSend = {
      id: id,
      ...data,
    };
    setLoading(true);

    ProductService.updateProduct(dataToSend)
      .then(() => {
        toast({
          title: t("productsPage.updateSuccess"),
          variant: "success",
        });
        navigate("/products/products");
      })
      .catch((err) => {
        toast({
          variant: "error",
          description: err.response.data?.errors[0].message,
        });
      })
      .finally(() => setLoading(false));
  };

  const fetchDocuments = async () => {
    ProductService.getDocuments(id).then((data) => {
      form.setValue("documents", data);
      setDocuments(data);
      setLoading(false);
    });
  };
  useEffect(() => {
    fetchDocuments();
  }, []);

  const onDeleteProductImage = () => {
    form.setValue("imageUrl", null);
    ProductService.deleteProductImage(id).catch((err) => {
      toast({
        variant: "error",
        description: err.response.data?.errors[0].message,
      });
    });
  };

  const onImageUpload = (id, file) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("file", file);

    ProductService.postProductImage(id, formData).then((res) => {
      form.setValue("imageUrl", res.data.imageUrl);
      toast({
        title: t("productsPage.imageUploadSuccess"),
        variant: "success",
      });

      setLoading(false);
    });
  };

  const videos = form.watch("merchantProductVideoList");

  const handleAddVideo = () => {
    const updatedVideos = [...videos, { videoTitle: "", videoURL: "" }];
    form.setValue("merchantProductVideoList", updatedVideos);
    form.trigger("merchantProductVideoList");
  };

  const handleDeleteVideo = (index) => {
    const updatedVideos = videos.filter((_, i) => i !== index);
    form.setValue("merchantProductVideoList", updatedVideos);
    form.trigger("merchantProductVideoList");
  };

  const handleVideoChange = (index, field, value) => {
    const updatedVideos = [...videos];
    updatedVideos[index] = {
      ...updatedVideos[index],
      [field]: value,
    };
    form.setValue("merchantProductVideoList", updatedVideos);
  };

  return (
    <main className="flex min-h-screen flex-col bg-[#F9FAFC]">
      <Breadcrumb>
        <BreadcrumbItem link="/products/products">
          {t("productsPage.products")}
        </BreadcrumbItem>
        <BreadcrumbItem active>{t("productsPage.editProduct")}</BreadcrumbItem>
      </Breadcrumb>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <div className="container mx-auto my-20 flex flex-col items-center justify-center gap-6">
            <div className="formBox">
              <h2 className="mb-5 text-lg font-medium">
                {t("productsPage.productCategory")}
              </h2>
              <Tag>{t(form.watch("categoryTree")?.split(" > ").pop())}</Tag>
            </div>
            <div className="formBox flex flex-col gap-4">
              <h2 className="mb-5 text-lg font-medium">
                {t("productsPage.productInfo")}
              </h2>
              <div className="grid grid-cols-2 gap-4">
                <FormField
                  control={form.control}
                  name="name"
                  render={({ field }) => (
                    <FormItem>
                      <Label>{t("productsPage.productName")}</Label>
                      <FormControl>
                        <Input maxLength={70} placeholder="" {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="model"
                  render={({ field }) => (
                    <FormItem>
                      <Label>{t("productsPage.productModel", "Model")}</Label>
                      <FormControl>
                        <Input maxLength={70} placeholder="" {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
              <div className="grid grid-cols-2 gap-4">
                <FormField
                  control={form.control}
                  name="warrantyDuration"
                  render={({ field }) => (
                    <FormItem>
                      <Label>{t("productsPage.warrantyDuration")}</Label>
                      <FormControl>
                        <Input
                          min="1"
                          placeholder=""
                          {...field}
                          type="number"
                        />
                      </FormControl>
                      <FormMessage />
                      <FormDescription>
                        {t("productsPage.warrantyDurationDesc")}
                      </FormDescription>
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="description"
                  render={({ field }) => (
                    <FormItem>
                      <Label>{t("productsPage.description")}</Label>
                      <FormControl>
                        <Textarea maxLength={1000} placeholder="" {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
              <div className="grid grid-cols-2 gap-4">
                <div className="flex gap-4">
                  <FormField
                    control={form.control}
                    name="priceUnit"
                    render={({ field }) => (
                      <FormItem>
                        <Label>{t("productsPage.priceUnit")} *</Label>
                        <Select
                          onValueChange={field.onChange}
                          value={field.value}
                        >
                          <FormControl>
                            <SelectTrigger>
                              <SelectValue />
                            </SelectTrigger>
                          </FormControl>
                          <SelectContent>
                            <SelectItem value="TRY">TRY</SelectItem>
                            <SelectItem value="USD">USD</SelectItem>
                            <SelectItem value="EUR">EUR</SelectItem>
                            <SelectItem value="GBP">GBP</SelectItem>
                            <SelectItem value="UZS">UZS</SelectItem>
                          </SelectContent>
                        </Select>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="price"
                    render={({ field }) => (
                      <FormItem>
                        <Label>{t("productsPage.price")} *</Label>
                        <FormControl>
                          <Input
                            type="number"
                            min="0"
                            step="0.01"
                            onChange={(e) =>
                              field.onChange(Number(e.target.value))
                            }
                            value={field.value}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
              </div>
              <div className="grid grid-cols-2 gap-4">
                {loading ? (
                  <div className="flex animate-pulse items-center justify-center rounded-lg bg-[#F2F5F9] p-2">
                    Loading...
                  </div>
                ) : (
                  <>
                    {form.watch("imageUrl") ? (
                      <div className="flex flex-col gap-3">
                        <Label>{t("productsPage.productImage")}</Label>
                        <FileLink
                          onDeleted={onDeleteProductImage}
                          url={form.watch("imageUrl")}
                        />
                      </div>
                    ) : (
                      <FileArea
                        title={t("productsPage.productImage")}
                        onFileUpload={(file) => onImageUpload(id, file)}
                      />
                    )}
                  </>
                )}
                <div className="flex items-center gap-6">
                  <FormField
                    control={form.control}
                    name="setupRequired"
                    render={({ field }) => (
                      <FormItem className="flex flex-col py-4">
                        <Label>{t("productsPage.installationRequired")}</Label>
                        <FormControl>
                          <Switch disabled checked={field.value} {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <hr className="h-12 w-px bg-[#E0E0E0]" />
                  <FormField
                    control={form.control}
                    name="additionalService"
                    render={({ field }) => (
                      <FormItem className="flex flex-col py-4">
                        <Label>{t("productsPage.additionalService")}</Label>
                        <FormControl>
                          <Switch
                            checked={field.value}
                            onCheckedChange={field.onChange}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
              </div>
            </div>
            <div className="formBox flex flex-col gap-4">
              <h2 className="mb-5 text-lg font-medium">
                {t("productsPage.productVideos")}
              </h2>
              {videos?.map((video, index) => (
                <div
                  key={index}
                  className="grid grid-cols-2 items-center gap-4 border-b pb-6"
                >
                  <FormField
                    control={form.control}
                    name={`merchantProductVideoList[${index}].videoTitle`}
                    render={() => (
                      <FormItem>
                        <Label>{t("productsPage.videoTitle")}</Label>
                        <FormControl>
                          <Input
                            value={video.videoTitle || ""}
                            onChange={(e) =>
                              handleVideoChange(
                                index,
                                "videoTitle",
                                e.target.value,
                              )
                            }
                          />
                        </FormControl>
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name={`merchantProductVideoList[${index}].videoURL`}
                    render={() => (
                      <FormItem>
                        <Label>{t("productsPage.videoURL")}</Label>
                        <FormControl>
                          <Input
                            value={video.videoURL || ""}
                            onChange={(e) =>
                              handleVideoChange(
                                index,
                                "videoURL",
                                e.target.value,
                              )
                            }
                          />
                        </FormControl>
                      </FormItem>
                    )}
                  />
                  <button
                    className="col-span-2 text-right font-medium text-[#F04438]"
                    onClick={(e) => {
                      e.preventDefault();
                      handleDeleteVideo(index);
                    }}
                  >
                    {t("productsPage.deleteVideo")}
                  </button>
                </div>
              ))}
              <button
                type="button"
                className="self-start font-medium text-[#0040C9]"
                onClick={handleAddVideo}
              >
                {t("productsPage.addVideo")}
              </button>
            </div>
            <div className="sticky bottom-0 flex w-full justify-end py-5">
              <Button
                size="lg"
                disabled={loading}
                onClick={form.handleSubmit(onSubmit)}
              >
                {t("common.save")}
              </Button>
            </div>
            <div className="formBox flex flex-col gap-4">
              <h2 className="mb-5 text-lg font-medium">
                {t("productsPage.documentInfo")}
              </h2>
              {loading ? (
                <p>Loading...</p>
              ) : (
                <DocumentsContainer
                  reFetchDocuments={fetchDocuments}
                  documents={documents}
                />
              )}
            </div>
          </div>
        </form>
      </Form>
    </main>
  );
};

export default ProductEditPage;

const DocumentsContainer = ({ documents, reFetchDocuments }) => {
  return (
    <div className="flex flex-col justify-between gap-12 md:flex-row">
      <DocumentLinkCard
        reFetchDocuments={reFetchDocuments}
        title={t("productsPage.warrantyDocument")}
        type="WARRANTY_DOCUMENT"
        document={documents?.find((doc) => doc.type === "WARRANTY_DOCUMENT")}
      />
      <DocumentLinkCard
        reFetchDocuments={reFetchDocuments}
        title={t("productsPage.userManual")}
        type="USER_MANUAL_DOCUMENT"
        document={documents?.find((doc) => doc.type === "USER_MANUAL_DOCUMENT")}
      />
    </div>
  );
};
