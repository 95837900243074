import { Button } from "@egaranti/components";

import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const NotFound = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className="flex min-h-screen flex-col items-center justify-center p-4">
      <div className="text-center">
        <h1 className="mb-4 text-6xl font-bold text-gray-900">404</h1>
        <h2 className="mb-4 text-2xl font-semibold text-gray-700">
          {t("notFound.title", "Sayfa Bulunamadı")}
        </h2>
        <p className="mb-8 text-gray-500">
          {t(
            "notFound.description",
            "Aradığınız sayfa bulunamadı veya taşınmış olabilir.",
          )}
        </p>
        <Button onClick={() => navigate("/")} className="px-6 py-2">
          {t("notFound.backToHome", "Ana Sayfaya Dön")}
        </Button>
      </div>
    </div>
  );
};

export default NotFound;
