import ExtendedWarrantyService from "@/services/extendedWarrantyService";

import { create } from "zustand";

const useExtendedWarrantyStore = create((set) => ({
  loading: false,
  extendedWarranties: [],
  packages: [],
  filters: {
    page: 1,
    size: localStorage.getItem("pageSize") || 10,
    direction: "ASC",
  },
  packageFilters: {
    page: 1,
    size: localStorage.getItem("pageSize") || 10,
    direction: "ASC",
  },
  getExtendedWarranties: async () => {
    set({ loading: true });
    try {
      const data = await ExtendedWarrantyService.getExtendedWarranties(
        useExtendedWarrantyStore.getState().filters,
      );
      set({
        extendedWarranties: data.content,
        loading: false,
        filters: {
          ...useExtendedWarrantyStore.getState().filters,
          totalPages: data.totalPages,
          page: data.page,
        },
      });
    } catch (error) {
      console.error("Error fetching extended warranties:", error);
      set({ loading: false });
    }
  },
  getPackages: async () => {
    set({ loading: true });
    try {
      const data = await ExtendedWarrantyService.getPackages(
        useExtendedWarrantyStore.getState().packageFilters,
      );
      set({
        packages: data.data,
        loading: false,
        packageFilters: {
          ...useExtendedWarrantyStore.getState().packageFilters,
          totalPages: data.totalPages,
          page: data.page,
        },
      });
    } catch (error) {
      console.error("Error fetching packages:", error);
      set({ loading: false });
    }
  },
  setFilters: (filters) => {
    set({ filters });
  },
  setPackageFilters: (packageFilters) => {
    set({ packageFilters });
  },
}));

export default useExtendedWarrantyStore;
