import {
  Button,
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@egaranti/components";

import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";

import AddNewDocument from "./addNewDocument";

import documentsService from "@/services/documentsService";
import productService from "@/services/productService";

import ConfirmationDialog from "@/components/shared/confirmDialog";

import RandomFileIcon from "@/assets/randon-file-icon.png";

import { ExternalLinkIcon, Unlink } from "lucide-react";

const DocumentLinkCard = ({ title, document, type, reFetchDocuments }) => {
  const { t } = useTranslation();
  const renderDocument = () => (
    <div className="flex w-full items-center justify-between rounded-lg border border-dashed bg-[#F2F5F9] p-4">
      <div className="flex items-center gap-2">
        <img
          className="h-10 w-10 rounded-lg bg-gray-300"
          src={RandomFileIcon}
          alt="file"
        />
        <div className="flex flex-col gap-2">
          <h3 className="text-sm font-medium text-[#111729]">
            {document?.name}
          </h3>
        </div>
      </div>
      <DocumentActions
        reFetchDocuments={reFetchDocuments}
        document={document}
        type={type}
      />
    </div>
  );

  const renderAddNewDocument = () => (
    <>
      <AddNewDocument reFetchDocuments={reFetchDocuments} type={type}>
        <AddNewDocumentButton />
      </AddNewDocument>
      {type === "WARRANTY_DOCUMENT" && (
        <p className="text-sm font-medium text-[#111729]">
          {t("documents.defaultDocumentUsed")}
          {""}
          <button
            type="button"
            className="text-[#0049E6] underline"
            onClick={() => {
              documentsService.previewDefaultWarrantyDocument();
            }}
          >
            {t("documents.show")}
          </button>
        </p>
      )}
    </>
  );

  return (
    <div className="flex w-full flex-col gap-4 rounded-lg border p-5">
      <h3 className="font-medium text-[#111729]">{title}</h3>
      {document ? renderDocument() : renderAddNewDocument()}
      {document && (
        <div className="flex w-full justify-center">
          <Button asChild>
            <Link target="_blank" to={`/settings/documents/${document?.id}`}>
              {t("documents.show")}
            </Link>
          </Button>
        </div>
      )}
    </div>
  );
};

export default DocumentLinkCard;

const AddNewDocumentButton = () => {
  const { t } = useTranslation();
  return (
    <div
      role="button"
      tabIndex="0"
      aria-label={t("settingsPage.addDocument")}
      className="flex w-full items-center gap-2 rounded-lg border border-dashed p-5 hover:border-gray-400"
    >
      <svg
        className="rounded-full border border-[#CCDBFA]"
        width="40"
        height="40"
        fill="none"
      >
        <rect width="40" height="40" rx="20" fill="#AAC2F7"></rect>
        <path
          d="M21.6668 11.8915V15.3337C21.6668 15.8004 21.6668 16.0337 21.7577 16.212C21.8376 16.3688 21.965 16.4963 22.1218 16.5762C22.3001 16.667 22.5335 16.667 23.0002 16.667H26.4423M26.6668 18.3238V24.3337C26.6668 25.7338 26.6668 26.4339 26.3943 26.9686C26.1547 27.439 25.7722 27.8215 25.3018 28.0612C24.767 28.3337 24.067 28.3337 22.6668 28.3337H17.3335C15.9334 28.3337 15.2333 28.3337 14.6985 28.0612C14.2281 27.8215 13.8457 27.439 13.606 26.9686C13.3335 26.4339 13.3335 25.7338 13.3335 24.3337V15.667C13.3335 14.2669 13.3335 13.5668 13.606 13.032C13.8457 12.5616 14.2281 12.1792 14.6985 11.9395C15.2333 11.667 15.9334 11.667 17.3335 11.667H20.01C20.6215 11.667 20.9272 11.667 21.2149 11.7361C21.47 11.7973 21.7139 11.8983 21.9375 12.0354C22.1898 12.19 22.406 12.4062 22.8384 12.8386L25.4953 15.4954C25.9276 15.9278 26.1438 16.144 26.2984 16.3963C26.4355 16.62 26.5365 16.8638 26.5978 17.1189C26.6668 17.4066 26.6668 17.7124 26.6668 18.3238Z"
          stroke="#0049E6"
          stroke-width="1.66667"
          strokeLinecap="round"
          stroke-linejoin="round"
        ></path>
      </svg>
      <h3 className="font-medium text-[#667085]">
        {t("claimsPage.uploadDocument")}
      </h3>
    </div>
  );
};

const DocumentActions = ({ type, document, reFetchDocuments }) => {
  const { t } = useTranslation();
  const { id } = useParams();

  const removeDocument = () => {
    productService.removeDocument(id).then(() => {
      reFetchDocuments();
    });
  };

  return (
    <div className="flex items-center gap-4">
      <a aria-label="İndir" target="_blank" href={document?.documentUrl}>
        <ExternalLinkIcon size={20} />
      </a>
      {type !== "WARRANTY_DOCUMENT" && (
        <>
          <TooltipProvider delayDuration={50}>
            <Tooltip>
              <TooltipTrigger type="button">
                <AddNewDocument reFetchDocuments={reFetchDocuments} type={type}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    strokeLinecap="round"
                    stroke-linejoin="round"
                  >
                    <path d="M12 3H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7" />
                    <path d="M18.375 2.625a1 1 0 0 1 3 3l-9.013 9.014a2 2 0 0 1-.853.505l-2.873.84a.5.5 0 0 1-.62-.62l.84-2.873a2 2 0 0 1 .506-.852z" />
                  </svg>
                </AddNewDocument>
              </TooltipTrigger>
              <TooltipContent>{t("documents.editDocument")}</TooltipContent>
            </Tooltip>
          </TooltipProvider>
          <ConfirmationDialog
            title={t("documents.dialog.confirmDeleteTitle")}
            description={t("documents.dialog.confirmDeleteDescription")}
            cancelText={t("common.cancel")}
            confirmText={t("common.confirm")}
            onConfirm={removeDocument}
            icon={
              <svg width="72" height="72" viewBox="0 0 72 72" fill="none">
                <rect
                  x="4"
                  y="4"
                  width="64"
                  height="64"
                  rx="32"
                  fill="#FFFAEB"
                />
                <rect
                  x="4"
                  y="4"
                  width="64"
                  height="64"
                  rx="32"
                  stroke="#FEF0C7"
                  stroke-width="8"
                />
                <path
                  d="M46.0193 25.9818C43.3756 23.3381 39.0975 23.3381 36.4568 25.9818L33.4287 29.01L35.0225 30.6037L38.0506 27.5756C39.7318 25.8943 42.5693 25.7162 44.4256 27.5756C46.285 29.435 46.1068 32.2693 44.4256 33.9506L41.3975 36.9787L42.9943 38.5756L46.0225 35.5475C48.66 32.9037 48.66 28.6256 46.0193 25.9818ZM33.9537 44.4256C32.2725 46.1068 29.435 46.285 27.5787 44.4256C25.7193 42.5662 25.8975 39.7318 27.5787 38.0506L30.6068 35.0225L29.01 33.4256L25.9818 36.4537C23.3381 39.0975 23.3381 43.3756 25.9818 46.0162C28.6256 48.6568 32.9037 48.66 35.5443 46.0162L38.5725 42.9881L36.9787 41.3943L33.9537 44.4256ZM28.135 26.5443C28.088 26.4978 28.0245 26.4717 27.9584 26.4717C27.8923 26.4717 27.8288 26.4978 27.7818 26.5443L26.5443 27.7818C26.4978 27.8288 26.4717 27.8923 26.4717 27.9584C26.4717 28.0245 26.4978 28.088 26.5443 28.135L43.8693 45.46C43.9662 45.5568 44.1256 45.5568 44.2225 45.46L45.46 44.2225C45.5568 44.1256 45.5568 43.9662 45.46 43.8693L28.135 26.5443Z"
                  fill="#F79009"
                />
              </svg>
            }
          >
            <button type="button">
              <Unlink size={20} />
            </button>
          </ConfirmationDialog>
        </>
      )}
    </div>
  );
};
