import React from "react";
import { NavLink, useLocation } from "react-router-dom";

import useAppStore from "@/stores/useAppStore";
import useInboxStore from "@/stores/useInboxStore";

import { cn, getHeaderTitle } from "@/lib/utils";

const MainHeader = () => {
  const title = getHeaderTitle(useLocation().pathname);
  const { unReadCount, getUnreadInboxCount } = useInboxStore();
  const { setOpenMobileMenu } = useAppStore();

  const isActive = (url) => {
    return useLocation().pathname === url;
  };

  React.useEffect(() => {
    getUnreadInboxCount();
  }, []);

  return (
    <header className="sticky top-0 flex h-[72px] w-full flex-row items-center justify-between border-b bg-white px-4 py-2">
      <div className="flex items-center gap-2">
        <button
          onClick={() => setOpenMobileMenu(true)}
          className="rounded-lg p-2 hover:bg-gray-100 lg:hidden"
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M3 12H21M3 6H21M3 18H21"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </button>
        <h2 className="text-xl font-medium text-gray-800">{title}</h2>
      </div>
      <div className="flex items-center gap-4">
        <NavLink
          to="/inbox"
          className={cn(
            "relative flex items-center rounded-lg p-3 font-medium text-[#111729] hover:bg-gray-100",
            {
              "bg-[#CCDBFA] text-[#002573]": isActive("/inbox"),
            },
          )}
        >
          <svg
            width="24"
            height="25"
            viewBox="0 0 24 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M2.5 12.5H5.88197C6.56717 12.5 7.19357 12.8871 7.5 13.5C7.80643 14.1129 8.43283 14.5 9.11803 14.5H14.882C15.5672 14.5 16.1936 14.1129 16.5 13.5C16.8064 12.8871 17.4328 12.5 18.118 12.5H21.5M8.96656 4.5H15.0334C16.1103 4.5 16.6487 4.5 17.1241 4.66396C17.5445 4.80896 17.9274 5.0456 18.2451 5.35675C18.6043 5.7086 18.8451 6.1902 19.3267 7.15337L21.4932 11.4865C21.6822 11.8645 21.7767 12.0535 21.8434 12.2515C21.9026 12.4275 21.9453 12.6085 21.971 12.7923C22 12.9992 22 13.2105 22 13.6331V15.7C22 17.3802 22 18.2202 21.673 18.862C21.3854 19.4265 20.9265 19.8854 20.362 20.173C19.7202 20.5 18.8802 20.5 17.2 20.5H6.8C5.11984 20.5 4.27976 20.5 3.63803 20.173C3.07354 19.8854 2.6146 19.4265 2.32698 18.862C2 18.2202 2 17.3802 2 15.7V13.6331C2 13.2105 2 12.9992 2.02897 12.7923C2.05471 12.6085 2.09744 12.4275 2.15662 12.2515C2.22326 12.0535 2.31776 11.8645 2.50675 11.4865L4.67331 7.15337C5.1549 6.19019 5.3957 5.7086 5.75495 5.35675C6.07263 5.0456 6.45551 4.80896 6.87589 4.66396C7.35125 4.5 7.88969 4.5 8.96656 4.5Z"
              stroke="#111729"
              stroke-width="1.66667"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          {unReadCount > 0 && (
            <span className="absolute right-1 top-1 inline-flex h-4 w-4 items-center justify-center rounded-full bg-blue-700 text-[10px] text-white">
              {unReadCount}
            </span>
          )}
        </NavLink>
      </div>
    </header>
  );
};

export default MainHeader;
